import React, { useState } from 'react';
import { Marker } from '@react-google-maps/api';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { MarkerType } from 'types/CommonTypes';
import { RealizationModal } from 'src/mother-website/RealizationModal/RealizationModal';
import { RealizationModalMobile } from 'src/mother-website/RealizationModalMobile/RealizationModal';

interface MapMarkerProps {
    marker: MarkerType;
    isMarkerClickable?: boolean;
}

export const MapMarker = ({ marker, isMarkerClickable }: MapMarkerProps) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const isMobile = useBreakpoint('md');

    const handleMarkerClick = (marker: MarkerType) => {
        marker.realization
            ? setModalIsOpen(true)
            : (window.location.pathname = `${marker.slug}`);
    };

    return (
        <>
            <Marker
                position={marker?.position}
                title={marker?.title}
                zIndex={1000}
                icon={{ url: marker?.iconUrl }}
                {...(isMarkerClickable && {
                    onClick: () => handleMarkerClick(marker),
                })}
            />
            {isMobile ? (
                <RealizationModalMobile
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    realization={marker?.realization}
                />
            ) : (
                <RealizationModal
                    modalIsOpen={modalIsOpen}
                    setModalIsOpen={setModalIsOpen}
                    realization={marker?.realization}
                />
            )}
        </>
    );
};
